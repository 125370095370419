// basic controller template   

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="<controller-name>"
export default class extends Controller {

    // Add to a view with data-controller="<controller-name>"
    // or data: { controller: '<controller-name' } in haml syntax
    // this is the equivalent of $(function() { ... }) in jquery.
    connect() {
        console.log("Loaded <controller-name> StimulusJS Controler", this.element)
    }
    
}
