import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="admin--account-settings"
export default class extends Controller {

  connect() {
    /*
      When the page first loads perform the following:
      
      IF the MDM is KnowInk managed
        1. Fetch the organization options/groups from the MDM
        2. Display the options in the select menu
        3. Make the already saved option the selected option
      
      IF the MDM is NOT KnowInk managed
        1. Display the API fields with the credentials already filled in
        2. Fetch the organization options/groups from the MDM
        3. Make the already saved option the selected option
    */
    this.updateSelectedMdm()

    const adminAccountEditPage = document.getElementById("admin-account-edit-page");

    if (adminAccountEditPage) {
      this.checkCountryRegions();

      document.getElementById("account_country").addEventListener("change", () => {
        this.checkCountryRegions();
      });
    }
  }

  checkCountryRegions() {
    const val = document.getElementById("account_country").value;
    const adminAccountRegionSelects = document.querySelectorAll(".admin-account-region-select");

    adminAccountRegionSelects.forEach(select => {
      select.style.display = select.getAttribute("data-id") === val ? "block" : "none";
    });
  }

  toggleMdmApiFieldsInView(e) {
    const knowink_managed_option_dropdown = e.srcElement

    // when KnowInk is NOT managing the MDM, show the API fields and move the already displayed options to the save template
    if (knowink_managed_option_dropdown.selectedOptions.length >= 1) {
      const user_provided_api_fields = document.querySelector('[data-target="mdm-api-fields"]')
      const selected_option = knowink_managed_option_dropdown.selectedOptions.item(0)

      // IF knowink is not managing the MDM, display the API fields and move the device organization options to the save template element
      if (selected_option.value == "false" && user_provided_api_fields.classList.contains("d-none")) {
        user_provided_api_fields.classList.remove('d-none')
        
        if (document.querySelector('[data-target="saved-mdm-device-grouping-select"]').innerHTML == "") {
          console.log("Should save and clear the mdm grouping options now")
          const device_organization_options_to_save = document.querySelector('[data-target="mdm-device-grouping-select"]')
          const mdm_device_grouping_template = document.querySelector('[data-target="saved-mdm-device-grouping-select"]')

          mdm_device_grouping_template.setAttribute("data-mdm-provider", document.querySelector('[data-target="mdm-provider"]').selectedOptions[0].value)
          mdm_device_grouping_template.innerHTML = device_organization_options_to_save.innerHTML
          device_organization_options_to_save.innerHTML = ""
        }
      } else if(selected_option.value == "true" && !user_provided_api_fields.classList.contains("d-none")) {
        // Fields will be ignored in the controller when saving, so no need to clear them from here
        user_provided_api_fields.classList.add('d-none')

        // If the current selected MDM is the original, re-add the options to the device grouping select menu
        if (document.querySelector('[data-target="saved-mdm-device-grouping-select"]').getAttribute('data-mdm-provider') == document.querySelector('[data-target="mdm-provider"]').selectedOptions[0].value) {
          const device_organization_options = document.querySelector('[data-target="mdm-device-grouping-select"]')
          const saved_mdm_device_grouping_template = document.querySelector('[data-target="saved-mdm-device-grouping-select"]')
          device_organization_options.innerHTML = saved_mdm_device_grouping_template.innerHTML
          document.querySelector('[data-target="saved-mdm-device-grouping-select"]').setAttribute('data-mdm-provider', "")
        }
      }
    }
  }

  // When the page first loads this
  updateSelectedMdm(e) {
    const csrf_token = document.querySelector('[name="csrf-token"]').content
    const selected_mdm = document.querySelector('[data-target="mdm-provider"]').selectedOptions[0]
    
    // save the API fields into the <template data-target="saved-mdm-api-fields"> element, if they have any saved values
    const select_menu = document.querySelector('[data-target="mdm-device-grouping-select"]')
    select_menu.innerHTML = ""
    
    const body = { "mdm_provider_selected": `${selected_mdm.value}` }
    const account_number = document.querySelector('form').dataset.accountId || 0
    // Change fetch to only happen if this featuer is not already enabled on page load
    fetch(
      `/admin/accounts/get_mdm_device_organization_groups?account_id=${account_number}`, 
      { 
        method: "POST",
        headers: 
          { 
            "X-CSRF-Token": csrf_token,
            "Content-Type": "application/json" 
          },
        body: JSON.stringify(body)
      }
    ).then((response) => response.json()).then((response_json) => {
      const menu_area = document.querySelector('[data-target="network-selection-area"]')

      const mdm_api_fields_area = document.querySelector('[data-target="mdm-api-fields"]')
      const select_menu = document.querySelector('[data-target="mdm-device-grouping-select"]')
      mdm_api_fields_area.innerHTML = '<div class="spacing"></div>'
      let already_chosen_device_grouping = ""
      
      if (select_menu.dataset.value != "") {
        localStorage.setItem("original_mdm_name", selected_mdm.text)
        localStorage.setItem("original_mdm_device_grouping", select_menu.dataset.value)
        already_chosen_device_grouping = select_menu.dataset.value
      }

      response_json.device_organization_options.forEach(element =>  {
        console.log("Element: ", element)
        const option = document.createElement("option")
        option.value = element.id
        option.text = element.name

        if (option.value == already_chosen_device_grouping) {
          option.setAttribute("selected", "true")
          select_menu.insertAdjacentElement('beforeend', option)
        } else {
          select_menu.insertAdjacentElement('beforeend', option)
        }
      })
      
      menu_area.classList.remove("d-none")

      response_json.api_input_fields.forEach(api_json_response => {
        const api_input_field = document.createElement("div")
        api_input_field.classList.add("edit-field")
        
        const label = document.createElement("label")
        label.innerText = `${api_json_response.label}`
        label.setAttribute("for", `account[mdm_configuration_meta_data][mdm_api][${api_json_response.meta_name}]`)

        const input_field = document.createElement("input")
        input_field.setAttribute("name", `account[mdm_configuration_meta_data][mdm_api][${api_json_response.meta_name}]`)

        switch (api_json_response.input_type) {
          case "password_field":
            input_field.type = "password"

            break
          default:
            input_field.type = "text"
        }

        api_input_field.insertAdjacentElement("beforeend", label)
        api_input_field.insertAdjacentElement("beforeend", input_field)
        mdm_api_fields_area.insertAdjacentElement("beforeend", api_input_field)
      })
      
      const api_fields_button = document.createElement("button")
      api_fields_button.innerText = "Get Available Device Groupings"
      api_fields_button.setAttribute("type", "submit")
      api_fields_button.setAttribute("data-action", "click->account-settings#updateMdmApiFields:prevent")
      api_fields_button.setAttribute("data-test-credentials", true)
      mdm_api_fields_area.insertAdjacentElement("beforeend", api_fields_button)
    })
  }

  /*****  Only Helper Methods Below -- aka not triggered from DOM actions ******/
  disableFormSubmission() { console.log ("The form should be disabled now.") }
  enabledFormSubmission() { console.log ("The form should be enabled now.") }
}
